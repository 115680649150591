'use client';

import { PrivyProvider } from '@privy-io/react-auth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import environment from '@trustblock/constants/environment.constants';
import { PostHogProvider } from 'posthog-js/react';
import type { ReactNode } from 'react';
import { initPosthog } from '../helpers/posthog';
//setup zod front needs to be imported in client component
import '@trustblock/validators/setupZodFront';

if (typeof window !== 'undefined') {
  initPosthog();
}

const queryClient = new QueryClient({});

export function Provider({ children }: { children: ReactNode }) {
  return (
    <PostHogProvider>
      <PrivyProvider
        appId={environment.PRIVY_APP_ID}
        config={{
          appearance: {
            logo: ''
          }
        }}
      >
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          {children}
        </QueryClientProvider>
      </PrivyProvider>
    </PostHogProvider>
  );
}
