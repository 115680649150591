import { z } from 'zod';

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  let variableName = issue.path.at(-1)?.toString() ?? '';

  if (variableName.length > 0) {
    variableName = `${variableName[0].toUpperCase()}${variableName.slice(1)}`;
  }

  // Use regex to convert camelCase or PascalCase to space-separated words with the second part in lowercase
  variableName = variableName
    .replace(/([a-z0-9])([A-Z])/g, (_, p1, p2) => `${p1} ${p2.toLowerCase()}`)
    .replace(/([A-Z])([A-Z][a-z])/g, (_, p1, p2) => `${p1} ${p2.toLowerCase()}`);

  switch (issue.code) {
    case z.ZodIssueCode.invalid_type:
      if (issue.received === 'undefined' || issue.received === 'null' || issue.received === 'nan') {
        return { message: `${variableName} is required.` };
      }
      return {
        message: `${variableName} should be of type ${issue.expected} but received type ${issue.received}.`
      };

    case z.ZodIssueCode.unrecognized_keys:
      return {
        message: `${variableName} contains unrecognized fields: ${issue.keys.map((key) => `${key}`).join(', ')}.`
      };

    case z.ZodIssueCode.invalid_literal:
      return {
        message: `${variableName} does not match the required value: ${issue.expected as string}.`
      };

    case z.ZodIssueCode.invalid_enum_value:
      return {
        message: `${variableName} should be one of the following: ${issue.options.map((key) => `${key}`).join(', ')}.`
      };

    case z.ZodIssueCode.invalid_arguments:
      return { message: `Invalid arguments provided for ${variableName}.` };

    case z.ZodIssueCode.invalid_return_type:
      return { message: `Invalid return type for ${variableName}.` };

    case z.ZodIssueCode.invalid_date:
      return { message: `${variableName} is not a valid date.` };

    case z.ZodIssueCode.invalid_string:
      if (issue.message) {
        return { message: issue.message };
      }
      if (issue.validation === 'email') {
        return { message: `${variableName} is not a valid email address.` };
      }
      if (issue.validation === 'uuid') {
        return { message: `${variableName} is not a valid UUID.` };
      }
      if (issue.validation === 'url') {
        return { message: `${variableName} is not a valid URL.` };
      }
      if (issue.validation === 'regex') {
        return { message: `${variableName} does not match the required pattern.` };
      }
      return { message: `Invalid string format for ${variableName}.` };

    case z.ZodIssueCode.too_small: {
      const minimum = issue.inclusive ? 'at least' : 'greater than';
      const suffix = issue.type === 'string' ? ' characters long' : '';
      return {
        message: `${variableName} is too small. It should be ${minimum} ${issue.minimum}${suffix}.`
      };
    }
    case z.ZodIssueCode.too_big: {
      const maximum = issue.inclusive ? 'at most' : 'less than';
      const suffix = issue.type === 'string' ? ' characters long' : '';
      return {
        message: `${variableName} is too large. It should be ${maximum} ${issue.maximum}${suffix}.`
      };
    }

    case z.ZodIssueCode.custom:
      return {
        message: issue.message ?? `Validation error at ${variableName}: ${ctx.defaultError}`
      };

    default:
      return { message: `Validation error at ${variableName}: ${ctx.defaultError}` };
  }
};

z.setErrorMap(customErrorMap);
